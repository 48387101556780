import supportedLanguageIds, { SupportedLanguage } from 'lib/lexemes/supported-languages';
import { supportedLanguages } from 'lib/supported-languages';

import {
    GLOBAL_DATING_TRENDS_DEFAULT_PATHNAME,
    GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_PHILILIPPINES_PATHNAME,
    GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_SINGAPORE_PATHNAME,
} from 'containers/global-dating-trends/global-dating-trends-constants';

const mapInstagramAccountByLanguage: { [key in SupportedLanguage]?: string } = {
    en: 'bumble',
    de: 'bumble_de',
    'en-in': 'bumble_india',
    hi: 'bumble_india',
};

const mapHrefLang: { [key in SupportedLanguage]?: string } = {
    en: 'en-gb',
};

export const PARTNER_NAME = 'Bumble';

export const SHARE_IMAGE_URL = 'https://bumble.com/bumble-brand-assets/bumble-share.jpg';
export const SOCIAL_SHARE_IMAGE_GLOBAL_REPORT_URL =
    'https://bumble.com/bumble-brand-assets/landing/assets/photos/global-dating-trends-hero-landscape.jpg';

export function setSharingImage(route: string) {
    let sharingImage = SHARE_IMAGE_URL;

    if (route === '/global-dating-trends') {
        sharingImage = SOCIAL_SHARE_IMAGE_GLOBAL_REPORT_URL;
    }
    if (route === '/[lang]/global-dating-trends') {
        sharingImage = SOCIAL_SHARE_IMAGE_GLOBAL_REPORT_URL;
    }
    return sharingImage;
}

export function getTwitterAccountByLanguage(): string {
    return 'bumble';
}

export function getInstagramAccountByLanguage(lang: SupportedLanguage): string {
    return (
        mapInstagramAccountByLanguage[lang] ||
        (mapInstagramAccountByLanguage[__DEFAULT_LANG__] as string)
    );
}

function setLanguageAlternates(baseUrl: string, path?: string, list?: string[]) {
    const languageList = list || (supportedLanguageIds as typeof supportedLanguageIds);
    return languageList.map((lang) => {
        // @ts-ignore
        const hrefLang = mapHrefLang[lang] || lang;

        return setLanguageAlternatesItem(baseUrl, hrefLang, lang, path);
    });
}

function setLanguageAlternatesItem(baseUrl: string, hrefLang: string, lang: string, path?: string) {
    let languageAlternateLink = `${baseUrl}/${lang}/${path || ''}`;

    if (!languageAlternateLink.endsWith('/')) {
        languageAlternateLink += '/';
    }

    return {
        hrefLang,
        href: languageAlternateLink,
    };
}

type getLanguageAlternatesProps = {
    baseUrl: string;
    route: string;
    query: any;
    pathname?: string;
    list?: string[];
};
export function getLanguageAlternates({
    baseUrl,
    route,
    query,
    pathname,
    list,
}: getLanguageAlternatesProps) {
    let languageAlternateList = setLanguageAlternates(baseUrl);
    const knownLangRoute = startsWithSupportedLanguage(query.lang);

    if (pathname && pathname.startsWith('/[lang]/')) {
        const knownPath = startsWithSupportedPath(pathname.replace('/[lang]', ''));

        if (knownPath) {
            languageAlternateList = setLanguageAlternates(
                baseUrl,
                getPathnameWithoutLangPrefix(pathname),
                list,
            );
        }
    }

    if (pathname) {
        const knownPath = startsWithSupportedPath(pathname);

        if (knownPath) {
            languageAlternateList = setLanguageAlternates(
                baseUrl,
                getPathnameWithoutLangPrefix(pathname),
                list,
            );
        }
    }

    if (knownLangRoute) {
        languageAlternateList = setLanguageAlternates(
            baseUrl,
            getPathnameWithoutLangPrefix(route),
            list,
        );
    }

    return languageAlternateList;
}

export function getCanonical(baseUrl: string, route: string) {
    let canonicalUrl = baseUrl;
    const pathName = route.includes('?') ? route.replace(/\?.+/g, '') : route;

    const knownPath = startsWithSupportedPath(route);
    const knownLangRoute = startsWithSupportedLanguage(route);

    if (knownPath) {
        canonicalUrl = `${baseUrl}/${getPathnameWithoutLangPrefix(pathName)}`;
    }

    if (knownLangRoute) {
        canonicalUrl = `${baseUrl}/${getPathnameWithoutLangPrefix(pathName)}`;
    } else {
        if (knownPath) {
            canonicalUrl = `${baseUrl}/en/${getPathnameWithoutLangPrefix(pathName)}`;
        }
    }

    if (!canonicalUrl.endsWith('/')) {
        canonicalUrl += '/';
    }

    if (canonicalUrl === `${baseUrl}/`) {
        canonicalUrl += 'en/';
    }

    return canonicalUrl;
}

function getPathnameWithoutLangPrefix(pathname: string) {
    const matches = /^(?:\/\[lang\])?(?:\/)?(.+)?$/.exec(pathname);
    return matches?.[1] || '';
}

/**
 * ask Creative team (James) for stats / new links
 */
export const downloadAction = 'https://bumble.onelink.me/3396940749/yuhjm82c';
export const downloadGlobalDatingTrendsReportAction =
    '/get-global-trends-report-2025?utm_source=report&utm_medium=web&utm_campaign=bumble_global_dating_trends&utm_id=bumble-global-dating-trends&utm_content=cta-button';
export const signInAction =
    '/get-started?utm_source=ab-test&utm_medium=web&utm_campaign=bumble_web_sign_in&utm_id=bumble-web-sign-in';

export function startsWithSupportedLanguage(url?: string) {
    if (!url) {
        return false;
    }

    return supportedLanguages.some((language) => {
        return url.startsWith(`/${language}/`);
    });
}

export function startsWithSupportedPath(url?: string) {
    const supportedPaths = [
        'about',
        'date',
        GLOBAL_DATING_TRENDS_DEFAULT_PATHNAME,
        GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_PHILILIPPINES_PATHNAME,
        GLOBAL_DATING_TRENDS_LOCATION_COUNTRY_SINGAPORE_PATHNAME,
    ];
    if (!url) {
        return false;
    }

    return supportedPaths.some((supportedPath) => {
        return url.startsWith(`/${supportedPath}`);
    });
}

export function removeTrailingSlash(url: string) {
    return url.replace(/\/$/, '');
}
