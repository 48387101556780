import * as React from 'react';

import { SupportedLanguage } from 'lib/lexemes/supported-languages';
import {
    getTwitterAccountByLanguage,
    getCanonical,
    getLanguageAlternates,
    PARTNER_NAME,
    setSharingImage,
} from 'lib/seo/seo';
import { DefaultSeo } from 'next-seo';
import { useRouter } from 'next/router';

import { GLOBAL_DATING_TRENDS_DEFAULT_LOCATION } from 'containers/global-dating-trends/global-dating-trends-constants';
import {
    isGlobalTrendsPageCheckPathname,
    isGlobalTrendsRegionPageCheckPathname,
    setRegionLanguageListToList,
} from 'containers/global-dating-trends/global-dating-trends-utils';
import { RouteName } from 'containers/routes';
import { seoDescription } from 'containers/seo/seo-description';
import { seoTitle } from 'containers/seo/seo-title';

export const domain = `https://bumble.com`;

export const SeoContainer: React.FunctionComponent<{ lang: SupportedLanguage }> = ({ lang }) => {
    const router = useRouter();
    const title = seoTitle[router.pathname as RouteName];
    const description = seoDescription[router.pathname as RouteName];
    const isGlobalTrendsPage = isGlobalTrendsPageCheckPathname(router.pathname);
    const isGlobalTrendsRegionPage = isGlobalTrendsRegionPageCheckPathname(router.pathname);
    let languageList;

    if (isGlobalTrendsPage) {
        languageList = setRegionLanguageListToList;
    }

    const languageAlternates = getLanguageAlternates({
        baseUrl: domain,
        route: router.asPath,
        query: router.query,
        pathname: router.pathname,
        list: languageList,
    });

    let canonical = lang
        ? languageAlternates.find((item) => {
              return item.hrefLang === lang;
          })?.href
        : getCanonical(domain, router.asPath);

    if (isGlobalTrendsPage) {
        if (isGlobalTrendsRegionPage) {
            canonical = `${domain}${router.pathname}/`;
        } else if (lang === 'en') {
            canonical = `${domain}/en${GLOBAL_DATING_TRENDS_DEFAULT_LOCATION}`;
        } else {
            canonical = `${domain}/${lang}${GLOBAL_DATING_TRENDS_DEFAULT_LOCATION}`;
        }
    }

    if (router.route === '/') {
        canonical = domain;
    } else if (router.route === '/date') {
        canonical = `${domain}/date/`;
    } else if (router.route === '/about') {
        canonical = `${domain}/about/`;
    }

    return (
        <DefaultSeo
            title={title}
            description={description}
            languageAlternates={languageAlternates}
            canonical={canonical}
            facebook={{
                appId: '428250913904849',
            }}
            additionalMetaTags={[
                {
                    name: 'apple-itunes-app',
                    content: 'app-id=930441707',
                },
                {
                    property: 'p:domain_verify',
                    content: '26cd87d4b7db08a05bdfa2219d3671b6',
                },
                {
                    property: 'referrer',
                    content: 'origin-when-cross-origin',
                },
                {
                    property: 'twitter:app:name:iphone',
                    content: title,
                },
                {
                    property: 'twitter:app:id:iphone',
                    content: '930441707',
                },
            ]}
            openGraph={{
                type: 'website',
                locale: lang,
                url: `${domain}/${lang}/`,
                site_name: PARTNER_NAME,
                title,
                description,
                images: [{ url: setSharingImage(router.route) }],
            }}
            twitter={{
                handle: '@bumble',
                cardType: 'summary_large_image',
                site: `@${getTwitterAccountByLanguage()}`,
            }}
        />
    );
};
